import {
  isRef,
  watch,
  onMounted,
  onBeforeUnmount,
  unref,
  Ref,
} from '@nuxtjs/composition-api'

export const useEventListener = (
  target: Ref<EventTarget | null> | EventTarget,
  event: string,
  handler: (e: Event | any) => any
) => {
  // if its a reactive ref, use a watcher
  if (isRef(target)) {
    watch(target, (value, oldValue) => {
      oldValue?.removeEventListener(event, handler, false)
      value?.addEventListener(event, handler, false)
    })
  } else {
    // otherwise use the mounted hook
    onMounted(() => {
      target.addEventListener(event, handler, false)
    })
  }
  // clean it up
  onBeforeUnmount(() => {
    unref(target)?.removeEventListener(event, handler, false)
  })
}
