import { ssrRef } from '@nuxtjs/composition-api'
import { Location } from 'vue-router'

export interface BreadcrumbItem {
  label: string
  to?: Location
  active?: boolean
}

const breadcrumbs = ssrRef<BreadcrumbItem[] | null>(null)

export const useBreadcrumb = () => {
  const setBreadcrumbs = (items: BreadcrumbItem[] | null) => {
    breadcrumbs.value = items
  }

  const resetBreadcrumbs = () => {
    setBreadcrumbs(null)
  }

  return {
    breadcrumbs,
    setBreadcrumbs,
    resetBreadcrumbs
  }
}
