import { computed, ref } from '@nuxtjs/composition-api'
import { Dictionary } from 'ramda'

// must write your customized siderbar component name in SIDEBAR_LIST
export enum Sidebars {
  AppSidebarMenu = 'AppSidebarMenu',
  ProductFilter = 'SidebarProductFilter',
}

export enum SidebarDiretion {
  Left = 'left',
  Right = 'right',
}

interface Config {
  diretion: SidebarDiretion
  local?: boolean
}

export const SidebarConfig = new Map<string, Config>([
  [Sidebars.AppSidebarMenu, { diretion: SidebarDiretion.Left }],
  [Sidebars.ProductFilter, { diretion: SidebarDiretion.Right }],
])

export const QUERY_FIELD = 'sidebarAttrs'

interface Sidebar {
  name: string | null
  attrs: Dictionary<any>
}

const current = ref<Sidebar>({
  name: null,
  attrs: {},
})

export const useSidebar = () => {
  const go = (name: Sidebars, attrs: Dictionary<any> = {}) => {
    //  name: your customized siderbar component name
    //  attrs: your customized siderbar props
    if (!name) return

    current.value.name = name
    current.value.attrs = attrs
  }

  const close = () => {
    current.value.name = null
    current.value.attrs = {}
  }

  const visible = computed(() => !!current.value.name)

  return {
    current,
    visible,
    go,
    close,
  }
}
