import { reactive, ref, toRefs, watch, Ref } from '@nuxtjs/composition-api'

export enum NotificationVariant {
  Plain = 'plain',
  Rounded = 'rounded',
}

export enum NotificationTheme {
  Default = 'default',
  Dark = 'dark',
}

export interface NotificationOptions {
  template: string
  variant?: NotificationVariant
  theme?: NotificationTheme
  timeout?: boolean
  fixed?: boolean
  duration?: number
}

const defaultOptions = {
  template: ``,
  variant: NotificationVariant.Plain,
  theme: NotificationTheme.Default,
  timeout: true,
  fixed: false,
  duration: 3000,
}

const visible = ref(false)
const state = reactive(defaultOptions)

export const useNotification = () => {
  const timer: Ref<ReturnType<typeof setTimeout> | null> = ref(null)

  const set = (options: NotificationOptions) => {
    const { template, variant, theme, timeout, fixed, duration } =
      Object.assign(defaultOptions, options)

    state.template = template
    state.variant = variant
    state.theme = theme
    state.timeout = timeout
    state.fixed = fixed
    state.duration = duration
  }

  const show = (options: NotificationOptions) => {
    // close prev notification
    if (visible.value) {
      close()
    }

    set(options)
    visible.value = true
  }

  const close = () => {
    visible.value = false
    set(defaultOptions)
  }

  watch(
    visible,
    (visible) => {
      if (visible && state.timeout) {
        timer.value = setTimeout(() => {
          close()
        }, state.duration)
      }
      if (!visible && timer.value) {
        clearTimeout(timer.value)
      }
    },
    {
      immediate: true,
    }
  )

  return {
    visible,
    ...toRefs(state),
    show,
    close,
  }
}
