import { ref, readonly } from '@nuxtjs/composition-api'

export const useLoading = (initialValue: boolean = false) => {
  const loading = ref(initialValue)
  const loadedHandlers = Array<() => void>(0)

  const scope = async <T>(
    action: () => Promise<T>,
    exclusive: boolean = false
  ) => {
    if (loading.value && !initialValue) {
      await new Promise((resolve: any) => {
        loadedHandlers.push(() => resolve())
      })
      if (exclusive) return
    }

    loading.value = true
    try {
      const result = await action()
      return result
    } finally {
      loading.value = false
      loadedHandlers.forEach((handler: any) => handler())
      loadedHandlers.splice(0, loadedHandlers.length)
    }
  }

  return {
    value: readonly(loading),
    scope
  }
}

export const useSignal = () => {
  const handlers = Array<() => void>(0)

  const set = () => {
    handlers.forEach((handler: any) => handler())
  }

  const wait = async () => {
    await new Promise((resolve: any) => {
      handlers.push(() => resolve())
    })
  }

  return {
    set,
    wait
  }
}