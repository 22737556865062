import { onBeforeUnmount, onMounted, Ref } from '@nuxtjs/composition-api'
import { useEventListener } from './useEventListener'

export const useClickOutside = (
  rootEl: Ref<HTMLElement | null>,
  callback: () => any
) => {
  // `mousedown` or `mouseup` is better than `click` here because it doesn't bubble up like `click`
  // if you've used `click` here, the callback will be run immediatly.
  if (process.client) {
    const onClickOutside = (e: Event) => {
      const clickedEl = e.target as HTMLElement

      // skip if the root element contains the clicked element
      if (rootEl.value?.contains(clickedEl)) return
      // otherwise execute the action
      callback()
    }

    const eventHandler = () => {
      if (
        navigator.maxTouchPoints > 0 ||
        (navigator as any).msMaxTouchPoints > 0
      ) {
        window.addEventListener('touchstart', onClickOutside, false)
        window.removeEventListener('mousedown', onClickOutside, false)
      } else {
        window.addEventListener('mousedown', onClickOutside, false)
        window.removeEventListener('touchstart', onClickOutside, false)
      }
    }

    onMounted(() => eventHandler())
    onBeforeUnmount(() => {
      window.removeEventListener('touchstart', onClickOutside, false)
      window.removeEventListener('mousedown', onClickOutside, false)
    })
    useEventListener(window, 'resize', eventHandler)
  }
}
